// ChatBot.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ChatBot.css";

const ChatBot = () => {
  const [message, setMessage] = useState("");
  const [conversation, setConversation] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Initial greeting message from Clarity
    setConversation([
      { sender: "bot", text: "Hi, my name is Clarity! I'm here to help with any questions about our services. Ask me about insurance, services, or just say hello!" }
    ]);
  }, []);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    // Add user's message to the conversation
    setConversation((prev) => [...prev, { sender: "user", text: message }]);
    

    try {
      const response = await axios.post("https://us-central1-kingcountypschiatry.cloudfunctions.net/chatbot", { message });
      setConversation((prev) => [
        ...prev,
        { sender: "bot", text: response.data.reply }
      ]);
    } catch (error) {
      setConversation((prev) => [
        ...prev,
        { sender: "bot", text: "Sorry, I'm having trouble responding right now. Please try again in a moment." }
      ]);
      console.error("Error sending message:", error);
    }

    setMessage("");
  };

  return (
    <>
      {isOpen ? (
        <div className="chatbot-container">
          <div className="chatbot-header">
            Clarity Support Bot
            <button onClick={() => setIsOpen(false)} style={{ background: "transparent", border: "none", color: "white" }}>
              ✖️
            </button>
          </div>
          <div className="chatbot-messages">
            {conversation.map((msg, index) => (
                <p key={index} className={msg.sender}>
                  <strong>{msg.sender === "user" ? "You" : "Clarity"}:</strong> {msg.text}
                  {msg.requiresAction && (
                    <div>
                      <a
                        href="https://www.tebra.com/care/provider/redempta-mwikya-pmhnp-1265047245"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="chatbot-link"
                      >
                        Click here to book an appointment or get more help
                      </a>
                    </div>
                  )}
                </p>
              ))}
          </div>
          <form className="chatbot-input" onSubmit={handleSendMessage}>
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type message..."
            />
            <button type="submit">Send</button>
          </form>
        </div>
      ) : (
        <button className="chatbot-button" onClick={() => setIsOpen(true)}>
          💬
        </button>
      )}
    </>
  );
};

export default ChatBot;
