import React from 'react';
import './about.css';
import aboutimage from '../../assets/about-img.jpg';

const AboutUs = () => {
  return (
    <section id="about" className="about-section">
      <header className="section-header">
        <h3>About Us</h3>
      </header>
      <div className="about-container">
        <div className="about-image-wrapper">
          <img src={aboutimage} alt="Redempta Mwikya" className="about-image"/>
          <div className="about-overlay">
            <h2>Redempta Mwikya</h2>
            <h3>(DNP)</h3>
            <p>Doctor of Nursing practice</p>
          </div>
        </div>
        <div className="about-content">
          <h3 className="about-title">Meet Our Founder</h3>
          <p>
            Redempta Mwikya, DNP (Doctor of Nursing practice), is a board-certified Psychiatric Mental Health Nurse Practitioner by the American Nurses Credentialing Center (ANCC). She offers individualized, holistic, and compassionate care using evidence-based practices in a safe environment. Services include psychiatric evaluations, counseling, and medication management with a special focus on each individual’s needs.
          </p>
          <div className="about-mission-vision">
            <div className="mission">
              <h2>Our Mission</h2>
              <p>Holistically address mental health disorders by prioritizing individuals' needs in a safe environment.</p>
            </div>
            <div className="vision">
              <h2>Our Vision</h2>
              <p>To end the stigma associated with mental health disorders by providing patient-tailored care, helping individuals fully integrate within the community.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;

