import React, { useState } from 'react';
import './Header.css';
import logo from "../../assets/logo.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle function to show or hide menu items
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {/* Top Banner */}
      <div className="top-banner">
        Appointments in as little as 2 days. Start your free assessment
      </div>

      {/* Navbar */}

      <nav className="navbar navbar-expand-lg navbar-custom navbar-light">
            <div className="container">
                
                {/* Collapsible Navbar Links - Left Side */}
                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''} order-1 order-lg-0`} id="navbarNavLeft">
                <ul className="navbar-nav me-auto">
                    <li className="nav-item">
                    <a className="nav-link"  style={{ color: "#264a69" }}  href="https://provider.kareo.com/mwikya-redempta">Getting Started</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link"  style={{ color: "#264a69" }}  href="https://provider.kareo.com/mwikya-redempta">Learn More</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link"  style={{ color: "#264a69" }}  href="/about">About Us</a>
                    </li>
                </ul>
                </div>

                {/* Centered Logo */}
                <a className="navbar-brand mx-lg-auto order-0" href="/">
                <img src={logo} alt="Logo" height="40" style={{ width: "180px", height: "42px", borderRadius: "10px", objectFit: 'cover' }} />
                </a>

                {/* Collapsible Navbar Links - Right Side */}
                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''} order-3 order-lg-2`} id="navbarNavRight">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                    <a className="nav-link" style={{ color: "#264a69" }}  href="https://provider.kareo.com/mwikya-redempta">Refer a Patient</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" style={{ color: "#264a69" }}  href="https://provider.kareo.com/mwikya-redempta">Member Login</a>
                    </li>
                    <li className="nav-item">
                    <a className="btn btn-assessment" style={{ color: "#fff", borderRadius: "28px", background: "#0d375c" }}  href="https://provider.kareo.com/mwikya-redempta">FREE ASSESSMENT</a>
                    </li>
                </ul>
                </div>

                {/* Navbar Toggler */}
                <button className="navbar-toggler" type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target="#navbarNavLeft #navbarNavRight" 
                    aria-controls="navbarNavLeft navbarNavRight" 
                    onClick={toggleNavbar}
                    aria-expanded={isOpen} aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                </button>
            </div>
        </nav>
    </div>
  );
};

export default Header;
