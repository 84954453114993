import React, { Component } from 'react'
import './Banner.css'
import yoga from "../../assets/yoga.png"

class Banner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      message: '',
      id: '',
      loading: false,
    }
  }


  render() {
    const { message, id, loading } = this.state
    return (
            <div class="banner">
                    <div class="row justify-content-center d-flex align-items-center">
                        <div class="col-md-6 text-left">
                            <h6 class="subheading" style={{
                                                            fontSize: "0.8rem",
                                                            fontWeight: "600",
                                                            color: "#4798e0"
                            }}>COMPASSIONATE CARE + EXPERT GUIDANCE</h6>
                            <h1>Personalized online therapy and psychiatry for anxiety, depression, and more</h1>
                            <p>Receive tailored treatment from experienced professionals who are with you every step of the way—
                                starting with a free, no-commitment consultation.</p>
                            <p class="mt-3" style={{fontSize: "17px"}}>Need help fast? Same-day appointments are available.</p>
                            <a href="https://provider.kareo.com/mwikya-redempta" class="btn btn-primary">START WITH A FREE ASSESSMENT</a>
                            <p class="mt-3" style={{fontSize: "24px", color: "#045797"}}>You're all-in-one platform for expert therapy and Personalized medication support.</p>
                        </div>
                        <div class="col-md-4">
                            <img src={yoga} alt="Yoga Pose" class="img-fluid yoga-img" style={{height: "570px"}}/>
                        </div>
                    </div>
            </div>
    )
  }
}

export default Banner
