import React, { useState, useEffect } from 'react'
import './Review.css'

const testimonials = [
  {
    quote: "My health has significantly improved! The care provided to me was exceptional! The medication I'm taking is excellent, and the personalized support I've received is remarkable! My medical professional listens to me with compassion and warmth!",
    author: "ARIA T",
    stars: "★★★★★",
  },
  {
    quote: "I finally feel understood and valued. My doctor genuinely cares, and it's refreshing to experience such kindness in healthcare!",
    author: "Liam G",
    stars: "★★★★★",
  },
  {
    quote: "Amazing experience from start to finish! I'm so grateful for the knowledge, patience, and dedication shown to me.",
    author: "Sophie R",
    stars: "★★★★★",
  },
  {
    quote: "The quality of care I received here exceeded my expectations. I'm in better health than ever!",
    author: "Jacob H",
    stars: "★★★★★",
  },
  {
    quote: "The team is phenomenal! I am treated with respect and the support has been invaluable in my journey.",
    author: "Emily K",
    stars: "★★★★★",
  },
];

const Review = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000);
    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
    <section className="testimonial-card-body">
      <div
        className="testimonial-carousel"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <div className="quote-icon">“</div>
            <p className="testimonial-quote">{testimonial.quote}</p>
            <div className="testimonial-author">{testimonial.author}</div>
            <div className="stars">{testimonial.stars}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Review;
